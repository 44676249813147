import client, { clientNames } from '~/graphql/apollo';
import type {
  ProductsQuery,
  ProductsQueryVariables,
} from '~/graphql/shopify/generated';
import { ProductSortKeys } from '~/graphql/shopify/generated';
import { ProductsDocument } from '~/graphql/shopify/generated';
import { getCountry } from '~/utils/locale';

const baseValues = {
  first: 6,
  variantsFirst: 250,
  query: 'available_for_sale:true',
};

export const fetchNewProductsDefaultVariables: ProductsQueryVariables = {
  ...baseValues,
  sortKey: ProductSortKeys.Id,
  reverse: true,
};

export const fetchNewProducts = (
  locale?: string,
  variables: ProductsQueryVariables = fetchNewProductsDefaultVariables,
) =>
  client
    .query<ProductsQuery>({
      query: ProductsDocument,
      variables: {
        ...variables,
        country: getCountry(locale),
      },
      fetchPolicy: 'network-only',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);

export const fetchBestSellerProductsDefaultVariables: ProductsQueryVariables = {
  ...baseValues,
  first: 6,
  sortKey: ProductSortKeys.BestSelling,
};

export const fetchBestSellerProducts = (
  locale?: string,
  variables: ProductsQueryVariables = fetchBestSellerProductsDefaultVariables,
) =>
  client
    .query<ProductsQuery>({
      query: ProductsDocument,
      variables: {
        ...variables,
        country: getCountry(locale),
      },
      fetchPolicy: 'network-only',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
