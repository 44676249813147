import NextLink from 'next/link';
import type { FC } from 'react';
import { useMemo } from 'react';

import {
  Box,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Image from '~/components/common/Image';
import type { ProductsQuery } from '~/graphql/shopify/generated';
import { isInPreOrder } from '~/utils/pre_order';
import { useFormatPriceRange } from '~/utils/price';
import { useStockStatus } from '~/utils/stock';

type Props = {
  idx: number;
  product: ProductsQuery['products']['nodes'][number];
};

type OptionName = 'Color' | 'Size';

const priorityIndex = 10;

export const ListItem: FC<Props> = ({ product, idx }) => {
  const { t } = useTranslation();
  const price = useFormatPriceRange(product.priceRange);
  // todo: compareAtPriceRangeのmaxとminが違った場合の処理
  const compareAtPrice = useFormatPriceRange(product.compareAtPriceRange);
  const discountPercentage = Math.floor(
    (1 -
      product.priceRange.maxVariantPrice.amount /
        product.compareAtPriceRange.maxVariantPrice.amount) *
      100,
  );
  const stockStatus = useStockStatus(product.totalInventory || 0);

  const selectedOptions = product.variants.nodes
    .map((n) => n.selectedOptions)
    .flat();
  const optionsString = (name: OptionName) => {
    const optionValues = selectedOptions
      .filter((option) => option.name == name)
      .map((o) => o.value);
    const uniqValues = Array.from(new Set(optionValues));
    return uniqValues.join('/');
  };
  const productTitleSuffix = useMemo(() => {
    if (isInPreOrder(product)) {
      return ' [予約]';
    }
    return '';
  }, [product]);
  return (
    <Box pos="relative">
      <NextLink href={`/products/${product.handle}`} passHref prefetch={false}>
        <Link>
          <Box
            pos="relative"
            width={['auto', null, 264]}
            height={['auto', null, 320]}
          >
            {product.featuredImage && (
              <Image
                priority={idx <= priorityIndex}
                src={product.featuredImage?.url || ''}
                width={1}
                height={1}
                alt={product.featuredImage?.altText || ''}
                layout="responsive"
                objectFit="contain"
              />
            )}
            {stockStatus === 'SoldOut' && (
              <Flex
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                bg="blackAlpha.300"
                opacity={0.7}
                justify="center"
                alignItems="center"
                _hover={{ opacity: 0.9 }}
                _active={{ opacity: 0.9 }}
              >
                <Text
                  color="#fff"
                  fontSize={[24, null, 30]}
                  fontWeight={700}
                  textAlign="center"
                >
                  {t('soldOut')}
                </Text>
              </Flex>
            )}
          </Box>
          <Text
            mt={4}
            fontSize={[12, null, 16]}
            fontWeight="bold"
            lineHeight="120%"
            fontFamily="sans-serif"
          >
            {product.vendor}
          </Text>
          <Text
            mt={4}
            fontSize={[12, null, 16]}
            fontWeight="bold"
            pb={2}
            lineHeight="120%"
          >
            {product.title}
            {productTitleSuffix}
          </Text>
          <Text fontSize={[10, null, 12]}>
            {optionsString('Size')} - {optionsString('Color')}
          </Text>
          <HStack>
            {compareAtPrice !== '¥0' && stockStatus !== 'SoldOut' ? (
              <SimpleGrid column={1}>
                <Text
                  fontSize={[10, null, 14]}
                  fontWeight="bold"
                  textDecorationLine="line-through"
                  color="blackAlpha.500"
                  lineHeight="normal"
                >
                  {compareAtPrice}
                </Text>
                <Text
                  fontSize={[12, null, 16]}
                  fontWeight="bold"
                  color="red.500"
                >
                  {price}
                </Text>
                <Text
                  fontSize={[10, null, 14]}
                  lineHeight="normal"
                  color="red.500"
                >
                  {`(${discountPercentage}%OFF)`}
                </Text>
              </SimpleGrid>
            ) : (
              <Text fontSize={[12, null, 16]} fontWeight="bold">
                {price}
              </Text>
            )}
            <Spacer />
            {stockStatus === 'IsLow' && (
              <Text
                px={[1, null, 2]}
                py={1}
                borderColor="blackAlpha.700"
                color="blackAlpha.700"
                backgroundColor="white"
                borderWidth={1}
                fontSize={10}
                fontWeight={700}
              >
                {t('stockIsLow')}
              </Text>
            )}
            {stockStatus === 'SoldOut' && (
              <Text
                px={[1, null, 2]}
                py={1}
                borderColor="black"
                fontSize={10}
                fontWeight={700}
              >
                {t('soldOut')}
              </Text>
            )}
          </HStack>
        </Link>
      </NextLink>
    </Box>
  );
};
