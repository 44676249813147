import { useRouter } from 'next/router';
import { useMemo } from 'react';

import locales from '~/data/locales.json';

export const useLocale = () => {
  const { locale } = useRouter();
  return useMemo(() => getLocale(locale), [locale]);
};

export const getLocale = (locale?: string) => {
  const found = locales.find((l) => l.locale === locale);
  if (!found) {
    return locales.find((l) => l.locale === 'ja_JP');
  }
  return found;
};

export const getCountry = (locale?: string) => {
  return getLocale(locale)?.country;
};

export const allLocales = () => locales.map((l) => l.locale);
