import type { FC, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
};

export const Header: FC<Props> = ({ children }) => (
  <Text
    pt={[10, null, 20]}
    pb={[6, null, 10]}
    fontWeight={700}
    fontSize={[20, null, 32]}
    textAlign="center"
  >
    {children}
  </Text>
);
