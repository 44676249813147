import client, { clientNames } from '~/graphql/apollo';
import type {
  ShopifyProductsQuery,
  ShopifyProductsQueryVariables,
} from '~/graphql/internal/generated';
import { ShopifyProductsDocument } from '~/graphql/internal/generated';
import type {
  CountryCode,
  InputMaybe,
  Product,
  ProductsByIdsQuery,
} from '~/graphql/shopify/generated';
import { ProductsByIdsDocument } from '~/graphql/shopify/generated';

export const searchProducts = async (
  country: InputMaybe<CountryCode>,
  perPage: number,
  variables: ShopifyProductsQueryVariables,
) => {
  const ids = await client
    .query<ShopifyProductsQuery>({
      query: ShopifyProductsDocument,
      variables,
      fetchPolicy: 'no-cache',
    })
    .then((res) => res.data.shopifyProducts.map((p) => p.id));
  const products = await client
    .query<ProductsByIdsQuery>({
      query: ProductsByIdsDocument,
      variables: {
        ids: ids.slice(0, perPage),
        variantsFirst: 250,
        country,
      },
      fetchPolicy: 'no-cache',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data)
    .then((res) => res.nodes as Product[])
    .then((res) => res.filter((p) => p));
  return {
    country,
    ids,
    products,
    pageInfo: { current: 1, perPage, total: ids.length },
  };
};
