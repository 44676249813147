import { useMemo } from 'react';

import type { MoneyV2, ProductPriceRange } from '~/graphql/shopify/generated';

import { useLocale } from './locale';

export const useFormatPrice = (price: MoneyV2): string => {
  const locale = useLocale();
  return useMemo(
    () =>
      new Intl.NumberFormat(locale?.country, {
        style: 'currency',
        currency: price.currencyCode,
      })
        .formatToParts(price.amount)
        .map((v) => v.value.replace('￥', '¥'))
        .join(''),
    [price, locale],
  );
};

export const useFormatPriceRange = (priceRange: ProductPriceRange): string => {
  const locale = useLocale();
  const min = Number(priceRange.minVariantPrice.amount);
  const max = Number(priceRange.maxVariantPrice.amount);
  if (min === max) {
    return new Intl.NumberFormat(locale?.country, {
      style: 'currency',
      currency: priceRange.minVariantPrice.currencyCode,
    })
      .formatToParts(min)
      .map((v) => v.value.replace('￥', '¥'))
      .join('');
  }
  return `${
    priceRange.minVariantPrice.currencyCode
  } ${min.toLocaleString()}~${max.toLocaleString()}`;
};
