import NextLink from 'next/link';
import type { FC, ReactNode } from 'react';

import type { ResponsiveValue } from '@chakra-ui/react';
import { Box, SimpleGrid, Link, Flex } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import type { Product } from '~/graphql/shopify/generated';

import { ListItem } from './ListItem';

type Props = {
  header?: ReactNode | string;
  products: Product[];
  moreHref?: string;
  columns?: ResponsiveValue<number>;
};

export const ProductList: FC<Props> = ({
  products,
  header,
  moreHref,
  columns,
}) => (
  <Box px={['16px', null, 20]} maxW={1080} mx="auto">
    {header && <Header>{header}</Header>}
    <SimpleGrid columns={columns || [2, null, 3]} spacing={[4, null, 16]}>
      {products.map((p, idx) => (
        <ListItem idx={idx} key={p.id} product={p as Product} />
      ))}
    </SimpleGrid>
    {moreHref && (
      <Flex justify="center" mt={14} pb={[20, null, 24]}>
        <NextLink href={moreHref} passHref>
          <Link
            px={12}
            py={3}
            bg="black"
            color="#fff"
            fontWeight={700}
            fontSize={[14, null, 18]}
            textAlign="center"
            width={['100%', null, '180px']}
          >
            MORE
          </Link>
        </NextLink>
      </Flex>
    )}
  </Box>
);
