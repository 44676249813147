import client, { clientNames } from '~/graphql/apollo';
import type {
  ProductsByTagQuery,
  ProductsByTagQueryVariables,
} from '~/graphql/shopify/generated';
import { ProductSortKeys } from '~/graphql/shopify/generated';
import { ProductsByTagDocument } from '~/graphql/shopify/generated';
import { getCountry } from '~/utils/locale';

export const fetchTagProductsDefaultValues: ProductsByTagQueryVariables = {
  first: 6,
  query: 'tag:tops',
  sortKey: ProductSortKeys.BestSelling,
  variantsFirst: 250,
};

export const fetchTagProducts = (
  locale?: string,
  variables: ProductsByTagQueryVariables = fetchTagProductsDefaultValues,
) =>
  client
    .query<ProductsByTagQuery>({
      query: ProductsByTagDocument,
      variables: {
        ...variables,
        country: getCountry(locale),
      },
      fetchPolicy: 'network-only',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
