import client, { clientNames } from '~/graphql/apollo';
import type {
  ArticlesQuery,
  ArticlesQueryVariables,
} from '~/graphql/shopify/generated';
import { ArticleSortKeys } from '~/graphql/shopify/generated';
import { ArticlesDocument } from '~/graphql/shopify/generated';

export const fetchArticlesDefaultVariables: ArticlesQueryVariables = {
  first: 3,
  sortKey: ArticleSortKeys.PublishedAt,
  reverse: true,
};

export const fetchArticles = (
  variables: ArticlesQueryVariables = fetchArticlesDefaultVariables,
) =>
  client
    .query<ArticlesQuery>({
      query: ArticlesDocument,
      variables,
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
