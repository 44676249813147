import client, { clientNames } from '~/graphql/apollo';
import type {
  ShopifyProductsQuery,
  ShopifyProductsQueryVariables,
} from '~/graphql/internal/generated';
import { ShopifyProductsDocument } from '~/graphql/internal/generated';
import type {
  CollectionProductsQuery,
  CollectionProductsQueryVariables,
  Product,
  ProductsByIdsQuery,
} from '~/graphql/shopify/generated';
import { ProductsByIdsDocument } from '~/graphql/shopify/generated';
import { CollectionProductsDocument } from '~/graphql/shopify/generated';
import { getCountry } from '~/utils/locale';

export const fetchCollectionProducts = async (
  locale: string,
  page: number,
  variables: ShopifyProductsQueryVariables,
) => {
  const res = await client
    .query<ShopifyProductsQuery>({
      query: ShopifyProductsDocument,
      variables,
      fetchPolicy: 'no-cache',
    })
    .then((res) => res.data);
  const products = await client
    .query<ProductsByIdsQuery>({
      query: ProductsByIdsDocument,
      variables: {
        ids: res.shopifyProducts.slice(0, 12).map((p) => p.id),
        locale,
      },
      fetchPolicy: 'no-cache',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data)
    .then((res) => res.nodes as Product[]);
  return {
    locale,
    products,
    pageInfo: { page, total: res.shopifyProducts.length },
  };
};

export const fetchRufflogProductsDefaultValues: CollectionProductsQueryVariables =
  {
    handle: 'rufflog-1',
    first: 6,
    variantsFirst: 250,
  };

export const fetchRufflogProducts = (
  locale?: string,
  variables: CollectionProductsQueryVariables = fetchRufflogProductsDefaultValues,
) =>
  client
    .query<CollectionProductsQuery>({
      query: CollectionProductsDocument,
      variables: {
        ...variables,
        country: getCountry(locale),
      },
      fetchPolicy: 'no-cache',
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
