import client, { clientNames } from '~/graphql/apollo';
import type {
  CollaborationsQuery,
  CollaborationsQueryVariables,
} from '~/graphql/shopify/generated';
import { CollectionSortKeys } from '~/graphql/shopify/generated';
import { CollaborationsDocument } from '~/graphql/shopify/generated';

export const fetchCollaborationsDefaultVariables: CollaborationsQueryVariables =
  {
    first: 3,
    sortKey: CollectionSortKeys.Id,
    query: 'x RUFFLOG',
  };

export const fetchCollaborations = (
  variables: CollaborationsQueryVariables = fetchCollaborationsDefaultVariables,
) =>
  client
    .query<CollaborationsQuery>({
      query: CollaborationsDocument,
      variables,
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
