import client, { clientNames } from '~/graphql/apollo';
import type {
  ShopQuery,
  ShopQueryVariables,
} from '~/graphql/shopify/generated';
import { ShopDocument } from '~/graphql/shopify/generated';

export const fetchShopDefaultShopQueryVariables: ShopQueryVariables = {
  first: 6,
  handleDrawer: 'link-list',
  handleHeader: '4-25',
};

export const fetchShopData = (
  variables: ShopQueryVariables = fetchShopDefaultShopQueryVariables,
) =>
  client
    .query<ShopQuery>({
      query: ShopDocument,
      variables,
      context: {
        clientName: clientNames.shopify,
      },
    })
    .then((res) => res.data);
