import { useMemo } from 'react';

const thresholdStockIsLow = 3;

type StockStatus = 'Ok' | 'IsLow' | 'SoldOut';

export const useStockStatus = (inventory: number): StockStatus => {
  return useMemo(() => {
    if (inventory <= 0) {
      return 'SoldOut';
    }
    return inventory <= thresholdStockIsLow ? 'IsLow' : 'Ok';
  }, [inventory]);
};
